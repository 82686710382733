import React, {useEffect, useRef, useState} from 'react';
import s from "./Portfolio.module.css";
import g from "../../global.module.css";
import infallibleImg from "./ProjectImages/infallible.png";
import project2 from "./ProjectImages/project2.png";
import educateImg from "./ProjectImages/educate.png";
import teverImg from "./ProjectImages/tever.webp";
import proBoxImg from "./ProjectImages/comfortCar.png";
import musicPlayerImg from "./ProjectImages/musicPlayer.png";
import Project from "./Project/Project";
import animateElementsToScroll from "../../Utils/Functions/AnimateElementsToScroll";
function Portfolio() {

    const projects = [
        {
            projectTitle:"Infallible Accounting Group",
            projectDescription:"Lorem ipsum dolor sit amet, consectetur adipisicing elit. At dolorum enim facere in minus rem. Ab, dolorem eum facere id impedit odit perferendis similique, sint tempora totam velit veritatis vero.",
            projectImg:infallibleImg,
            projectLink:"https://www.infallibleaccgroup.com/",
        },
        {
            projectTitle:"Hydra - Project",
            projectDescription:"Lorem ipsum dolor sit amet, consectetur adipisicing elit. At dolorum enim facere in minus rem. Ab, dolorem eum facere id impedit odit perferendis similique, sint tempora totam velit veritatis vero.",
            projectImg:project2,
            projectLink:"https://hydra-psi.vercel.app/",
        },
        {
            projectTitle:"IEducate",
            projectDescription:"Lorem ipsum dolor sit amet, consectetur adipisicing elit. At dolorum enim facere in minus rem. Ab, dolorem eum facere id impedit odit perferendis similique, sint tempora totam velit veritatis vero.",
            projectImg:educateImg,
            projectLink:"https://ieducate.am/",
        },
        {
            projectTitle:"Tever - Group",
            projectDescription:"Lorem ipsum dolor sit amet, consectetur adipisicing elit. At dolorum enim facere in minus rem. Ab, dolorem eum facere id impedit odit perferendis similique, sint tempora totam velit veritatis vero.",
            projectImg:teverImg,
            projectLink:"https://tevergroup.am/",
        },
        {
            projectTitle:"ProBox",
            projectDescription:"Lorem ipsum dolor sit amet, consectetur adipisicing elit. At dolorum enim facere in minus rem. Ab, dolorem eum facere id impedit odit perferendis similique, sint tempora totam velit veritatis vero.",
            projectImg:proBoxImg,
            projectLink:"https://comfort-car.vercel.app/",
        },
        {
            projectTitle:"Music Player",
            projectDescription:"Lorem ipsum dolor sit amet, consectetur adipisicing elit. At dolorum enim facere in minus rem. Ab, dolorem eum facere id impedit odit perferendis similique, sint tempora totam velit veritatis vero.",
            projectImg:musicPlayerImg,
            projectLink:"https://music-player-self-delta.vercel.app/",
        },
    ]

    const showAnimations = ['animate__lightSpeedInLeft',"animate__zoomIn","animate__lightSpeedInRight","animate__lightSpeedInLeft","animate__zoomIn","animate__lightSpeedInRight"]
    const hideAnimations = ['animate__lightSpeedOutLeft',"animate__zoomOut","animate__lightSpeedOutRight","animate__lightSpeedOutLeft","animate__zoomOut","animate__lightSpeedOutRight"]

    const portfolioTitleRef = useRef(null);

    const [scrollTop, setScrollTop] = useState(0);

    useEffect(() => {
        const handleScroll = event => {
            animateElementsToScroll(scrollTop,setScrollTop,portfolioTitleRef,'animate__zoomIn','animate__zoomOut',`${s.portfolioTitleShow}`)
        }
        window.addEventListener('scroll',handleScroll);

        return () => {
            window.removeEventListener('scroll',handleScroll);
        }
    })
    return (
        <section
            className={s.portfolio}
            id="portfolio"
        >
            <h2 ref={portfolioTitleRef}>Latest <span className={g.animatedText}>Projects</span></h2>
            <div className={s.projects}>
                {projects.map((project,i) => {
                    return <Project project={project} showAnimation={showAnimations[i]} hideAnimation={hideAnimations[i]}/>
                })}
            </div>
        </section>
    );
}

export default Portfolio;